import React, { useEffect, useState } from "react";
import { BlogContainer, BlogContent } from "../components/Blog/blogStyles";
import Post from "../components/Blog/Post";
import Header from "../components/Header/Header";
import { getBlogPosts } from "../providers/mainProvider";
import { ButtonPrimary } from "../styles/buttons";

const BlogScreen = props => {

  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(false);

  useEffect(() => {
    //console.log("page", page);
    if(limit) return;
    if(loading) return;

    const getData = async () => {
      setLoading(true);
      const result = await getBlogPosts(page);
      if(result.error) {
        setError("Houve um problema ao carregar as notícias");
        setLoading(false);
        return;
      }
      //console.log(result.results);
      setPosts(state => [...state, ...result.results]);
      if(!result.next) {
        setLimit(true);
      }
      setLoading(false);
    }
    getData();
  }, [page, limit])

  const nextPage = () => {
    if(limit) return;
    setPage(page + 1);
  }

  return (
    <BlogContainer>
      <Header />
      <BlogContent>

        

        <h1>Notícias</h1>

          {posts.map(post => (
            <Post 
              key={`post-${post.id}`}
              title={post.title}
              content={post.text}
              photo={post.image}
              date={post.created_at}
            />
          ))}

          {/* <Post 
            title="Um título aqui"
            content={content}
            photo="https://marshalledc.org/sites/default/files/banner/mountains.jpg"
          />

          <Post 
            title="Um título maior aqui, que ocupe mais de uma linha pelo menos"
            content={content}
            photo="https://marshalledc.org/sites/default/files/banner/mountains.jpg"
          /> */}

          {limit ? null : (
            <ButtonPrimary
              className="center-btn"
              onClick={nextPage}
            >
              {loading ? 'Carregando notícias' : 'Ver notícias mais antigas'}
            </ButtonPrimary>
          )}

      </BlogContent>
    </BlogContainer>
  );
}

export default BlogScreen;


const content = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque dignissim ligula a feugiat blandit. Cras varius mauris vel blandit molestie. Vivamus interdum rutrum est at fermentum. Fusce eget gravida nibh, et consequat ex. Fusce nisi ipsum, molestie id elementum non, viverra a felis. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Suspendisse potenti. Sed accumsan eros venenatis gravida tincidunt. Aenean malesuada mi justo, ut viverra ipsum condimentum volutpat. Aenean quis est a elit semper auctor sed in lectus. Cras eros est, mollis sed congue eget, pulvinar ultricies leo. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Pellentesque eget nibh quis lacus auctor pharetra non ac ante. Suspendisse potenti. Nullam malesuada lacus a sapien rutrum viverra. Nunc viverra, tortor sed ullamcorper dignissim, mauris ex venenatis dui, ultrices aliquet enim nunc ac odio.

Integer iaculis dolor turpis, sed gravida eros suscipit nec. Vivamus odio ipsum, vulputate eget maximus et, egestas et velit. Donec eu odio varius, dapibus ex convallis, consectetur nisi. Etiam ex dui, fermentum non malesuada eu, fermentum ac dui. Suspendisse potenti. Phasellus a blandit erat, ac bibendum ipsum. Sed nisi leo, lobortis vel convallis quis, eleifend vitae quam. Proin luctus lectus eros, ac finibus nisl imperdiet a.

Cras felis tellus, interdum sed nisi eget, dignissim posuere lorem. Fusce ullamcorper auctor purus. Phasellus quis malesuada libero. Sed id commodo tortor. Curabitur sit amet aliquam elit. Vivamus molestie risus turpis, vel pretium enim pulvinar sit amet. Donec vitae est vel lacus fringilla bibendum a eget lectus. Integer pellentesque porta nisi vitae luctus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Donec in ipsum augue. Nam massa nibh, porttitor vel risus sed, maximus finibus odio. Curabitur vel cursus eros.

Proin a convallis lorem. Nulla at tincidunt odio, eget dignissim arcu. Donec tincidunt nisi at felis pharetra rutrum. Aenean id nisi magna. Sed pulvinar pretium eros, at ultrices dolor. Nullam molestie, ex ut euismod mattis, neque magna ullamcorper dolor, nec vestibulum neque augue sit amet tortor. Sed elementum aliquam velit, eu consectetur felis vestibulum vitae. Quisque sollicitudin lectus vitae diam laoreet, vitae vehicula dui commodo. Vestibulum sit amet volutpat urna, in convallis mauris. Praesent ornare neque id sapien pharetra, sed congue nisl mollis. Curabitur dignissim molestie mollis.

In lobortis tincidunt diam quis efficitur. Sed non massa tellus. Vestibulum lacinia nisi a diam congue luctus. Nulla sit amet lobortis erat. Duis venenatis, odio id condimentum interdum, tellus nulla euismod nisi, ac commodo dui eros quis nibh. Mauris semper ex ut semper cursus. Interdum et malesuada fames ac ante ipsum primis in faucibus. Ut vehicula rutrum justo sed mattis. Nam interdum rutrum hendrerit. Aenean sit amet molestie nulla. Ut sit amet dolor finibus, ultrices erat ac, egestas massa. Pellentesque auctor lacus at erat facilisis scelerisque. Praesent sit amet dolor eget purus vehicula vestibulum.`
