import styled from "styled-components";

export const Button = styled.button`
  color: var(--color-font);
  box-sizing: border-box;
  text-decoration: ${({ underline = false }) =>
    underline ? "underline" : "none"};
  cursor: pointer;
  font-size: ${({ fontSize = "1rem" }) => `${fontSize}`};
  padding: 0.75rem 1rem;
  border-radius: 4px;
  a {
    color: inherit;
    text-decoration: none;
    font-weight: inherit;
    text-decoration: ${({ underline = false }) =>
      underline ? "underline" : "none"};
  }
  opacity: ${({ disabled = false }) => disabled ? "50%" : "100%"};
`;

export const LinkButton = styled(Button)`
  text-decoration: underline;
  text-transform: none;
  /* margin: ${({ inline = false }) => inline ? "0" : "inherit"}; */
  padding: ${({ inline = false }) => inline ? "0" : "0.75rem 1rem"};
  display: ${({ inline = false }) => inline ? "inline" : "inherit"};
`;

export const Link = styled.a`
  text-decoration: ${({ underline = false }) =>
    underline ? "underline" : "none"};
`;

export const IconButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  text-transform: none;
  text-decoration: none;
  &::before {
    content: "";
    margin-right: 0.5rem;
    background-image: ${props => `url(${props.icon})`};
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
    width: ${({ width = "1.3rem" }) => `${width}`};
    height: ${({ height = "1.3rem" }) => `${height}`};
  }
`;

export const ImageButton = styled(Button)`
  font-size: 0;
  padding: 0;
  margin: 0.75rem 1rem;
  width: ${({ width = "1.5rem" }) => `${width}`};
  height: ${({ height = "1.5rem" }) => `${height}`};
  background-image: ${props => `url(${props.icon})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

export const ButtonPrimary = styled(Button)`
  max-height: 42px;
  font-weight: 600;
  min-width: 120px;
  background-color: var(--color-font);
  color: var(--color-bg);
`;
