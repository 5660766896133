import React from "react";
import { PostContainer } from "./blogStyles";

const Post = ({title, content, photo, date}) => {

  const d = new Date(date)
  const month = d.toLocaleDateString('pt-br', {month: 'short'});
  const day = d.toLocaleDateString('pt-br', {day: 'numeric'})
  const year = d.toLocaleDateString('pt-br', {year: 'numeric'})

  return (
    <PostContainer>

      <header>
        <h2>{title}</h2>
        {photo ? (
          <img src={photo} alt={`imagem de ${title}`} />
        ) : null}

        <aside className="date">
          <span className="day">{day}</span>
          <span className="month">{month}</span>
          <span className="year">{year}</span>
        </aside>
      </header>

      <p>
        {content}
      </p>
      
    </PostContainer>
  )
}

export default Post;
