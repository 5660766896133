import styled from "styled-components";

export const AboutUsContainer = styled.section`

  padding: 2rem 1rem;
  background-color: var(--color-secondary);
  color: var(--color-bg);

  p {
    margin: 1rem auto;
    max-width: 900px;
    font-size: 1.175rem;
    text-align: justify;
  }

  header {
    p {
      text-align: center;
      max-width: 600px;
      margin: auto;
    }
  }

  ul {
    max-width: 1100px;
    margin: auto;
  }

  ul > li {
    margin: 1.5rem;
    display: flex;
    align-items: center;
    &::before {
      content: "\\2713";
      margin-right: 1rem;
      border: 2px solid white;
      border-radius: 50%;
      display: inline-block;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
    }
  }
  
`