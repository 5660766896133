import styled from "styled-components";

export const CustomersContainer = styled.section`

  padding: 2rem 2rem;
  padding-bottom: 4rem;
  --max-slide-height: 160px;

  .section-header::after {
    background-color: var(--color-font);
  }

  .carousel-customers {
    padding: 1rem;
    max-height: 100vw;
    max-width: 1020px;
    margin: auto;

    .carousel__slider.carousel__slider--horizontal {
      max-width: 1020px;
      max-height: var(--max-slide-height);
      height: fit-content;
    }

    .slideHorizontal___1NzNV {
      max-height: var(--max-slide-height);
    }

    .slideInner___2mfX9 {
      max-height: var(--max-slide-height);
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
    .slide-customers {
      display: flex;
      justify-content: center;
      align-items: center;
      max-height: var(--max-slide-height);
      margin: 1rem;
      flex: 1;
    }
    img {
      flex: 1;
      object-fit: contain;
      max-width: 100%;
      max-height: calc(var(--max-slide-height)/2);
      /* max-height: 60vh; */
      display:block;
      margin: auto;
    }

    @media (min-width: 890px) {
      --max-slide-height: 280px;
      .slideInner___2mfX9 {
        flex-direction: column;
      }
    }
  }

  

  /* .image-grid {
    display: grid;
    gap: 1rem;
    justify-items: center;
    align-items: center;
    max-width: 1100px;
    margin: auto;

    img {
      object-fit: contain;
      max-width: 100%;
    }

    @media (min-width: 720px) {
      grid-template-columns: 1fr 1fr;
    }

    @media (min-width: 1028px) {
      grid-template-columns: 1fr 1fr 1fr;
      gap: 2rem;
    }

    @media (min-width: 1268px) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }

  } */

  
`
