import React, { useEffect, useState } from "react";
import { Button, ImageButton } from "../../styles/buttons";
import { LandingHeader, NavMenu } from "./headerStyles";

import Logo from "../../assets/logo-symbol.png";
import { Link } from "react-router-dom";

const Header = props => {
  const [showNav, setShowNav] = useState(false);
  const [headerShrunk, setHeaderShrunk] = useState(false);

  // console.log("render headerShrunk", headerShrunk);

  useEffect(() => {
    const handleScroll = () => {
      const position = document.body.getBoundingClientRect();
      //console.log(position);
      if(position.top <= -20) {
        //if(headerShrunk === true) return;
        setHeaderShrunk(true);
      } else {
        //if(headerShrunk === false) return;
        setHeaderShrunk(false);
      }
    }
    window.addEventListener('scroll', handleScroll);

    // return window.removeEventListener('scroll', handleScroll);
  }, [])

  return (
    <LandingHeader shrunk={headerShrunk}>
      
      <Link to="/" >
        <ImageButton 
          width={`${headerShrunk ? 0.7*243 : 243}px`}
          height={`${headerShrunk ? 0.7*90 : 90}px`} 
          icon={Logo}
        >
            Contabilizar
        </ImageButton>
      </Link>


      <NavMenu show={showNav} shrunk={headerShrunk}>
        <a href="/#quem-somos" onClick={() => setShowNav(!showNav)}>
          <Button>Quem somos nós</Button>
        </a>
        <a href="/#servicos" onClick={() => setShowNav(!showNav)}>
          <Button>Serviços</Button>
        </a>
        <a href="/#contato" onClick={() => setShowNav(!showNav)}>
          <Button>Contato</Button>
        </a>
        <Link to="/noticias" onClick={() => setShowNav(!showNav)}>
          <Button>Notícias</Button>
        </Link>
      </NavMenu>
      
      <Button id="menu-btn" onClick={() => setShowNav(!showNav)}>
        {showNav ? "Fechar" : "Menu"}
      </Button>
      
    </LandingHeader>
  )
}

export default Header;
