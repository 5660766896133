import React, { useEffect, useState } from "react";
import { ServicesContainer } from "./servicesStyles";
import { getServices } from "../../providers/mainProvider";
import { CarouselProvider, Slider, Slide, DotGroup, ButtonBack, ButtonNext } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

const Services = props => {

  const [list, setList] = useState([]);
  const [visibleSlides, setVisibleSlides] = useState(1);

  useEffect(() => {
    const width = window.screen.width;
    if(width > 934) {
      setVisibleSlides(3);
      return;
    }
  }, []);

  useEffect(() => {
    const getData = async () => {
      const result = await getServices();
      setList(result);
    }
    getData();
  }, []);

  return (
    <ServicesContainer id="servicos">
      <header className="section-header">
        <h2>Serviços oferecidos</h2>
        <h3>O que fazemos?</h3>
      </header>

      <CarouselProvider
        naturalSlideWidth={16}
        naturalSlideHeight={92}
        totalSlides={list.length}
        isPlaying={false}
        visibleSlides={visibleSlides}
        infinite={true}
        className="pure-carousel carousel-services"
      >
        <Slider>
            
          {list.map((item,index) => (
            <Slide index={index} key={`service-${item.id}`}>
              <div className="slide-service">
                <img src={item.image} alt={item.title}/>
                <h4>{item.title}</h4>
                <p>{item.description}</p>
              </div>
            </Slide>
          ))}
              
        </Slider>
        <ButtonBack></ButtonBack>
        <ButtonNext></ButtonNext>
        <DotGroup />
      </CarouselProvider>
      
      {/* <ul>
        {list.map(item => (
          <li key={`service-${item.id}`}>
            <img src={item.image} alt={item.title}/>
            <h4>{item.title}</h4>
            <p>{item.description}</p>
          </li>
        ))}
      </ul> */}
      
    </ServicesContainer>
  )
}

export default Services;
