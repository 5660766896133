import styled from "styled-components";

export const BlogContainer = styled.div`
  --header-top-color: var(--color-bg);
  --header-content-color: var(--color-font);
`

export const BlogContent = styled.main`


  padding: 1rem;
  padding-top: var(--header-height);

  h1 {
    text-align: center;
  }

  .center-btn {
    margin: 1rem auto;
    display: block;
  }



`

export const PostContainer = styled.article`
  padding-bottom: 1.5rem;
  max-width: 800px;
  margin: auto;
  
  &:not(:first-of-type) {
    border-top: 1px solid var(--color-font-light);
    padding-top: 2rem;
  }

  .date {
    background-color: var(--color-primary);
    width: fit-content;
    color: var(--color-bg);
    padding: 0.5ch 0.75ch;
    margin-top: 0.5ch;
    border-radius: 6px;
    font-weight: 600;
    text-transform: uppercase;

    span:not(:last-child) {
      margin-right: 0.75ch;
    }
  }

  h2 {
    text-align: left;
    font-size: 1.5rem;
    margin: 0.5rem 0;
  }

  p {
    text-align: justify;
  }

  header {
    margin-bottom: 1.5rem;
    img {
      max-width: 100%;
      border-radius: 6px;
    }
  } 

`
