import React from "react";

import AboutUs from '../components/AboutUs/AboutUs';
import Carousel from '../components/Carousel/Carousel';
import Contact from '../components/Contact/Contact';
import Customers from '../components/Customers/Customers';
import Header from "../components/Header/Header";
import Services from '../components/Services/Services';
import Testimonials from '../components/Testimonials/Testimonials';

const HomeScreen = props => (
  <>
    <Header />

    <Carousel />

    <AboutUs />

    <Testimonials />

    <Contact />

    <Services />

    <Customers />
  </>
);

export default HomeScreen;
