import React from "react";
import { AboutUsContainer } from "./aboutUsStyles";

const AboutUs = props => {
  return (
    <AboutUsContainer id="quem-somos">
      <header className="section-header">
        <h2>Quem somos nós</h2>
        {/* <p>A GM Engenharia Dimensional é uma empresa que atua no segmento da Agrimensura, e tem como especialidade a Topografia de Precisão.</p> */}
      </header>

      <p>
      A GM Engenharia Dimensional é uma empresa que atua no segmento da Agrimensura, e tem como especialidade a Topografia de Precisão. Atuamos no gerenciamento total da obra, da fundação ao acabamento, do levantamento Planialtimétrico e concepção do Projeto, até a entrega. Aliada com inovação, responsabilidade, tecnologia, qualidade, agilidade e eficiência gerencial.
      </p>

      <p>
      Para isso, contamos com Profissionais experientes e qualificados, atuantes em diferentes áreas, como Construção Civil e Agricultura, capazes de atender de forma ágil as necessidades de cada cliente. Para maximizar o grau de satisfação dos clientes e colaboradores, a GM Engenharia Dimensional entende que é fundamental assegurar as condições de trabalho propícias à boa execução dos seus serviços, e à satisfação de todos que com ela interagem, bem como
desempenhar sua função social de preservação ao Meio Ambiente. Nossa Empresa possui escritório no Recife e Petrolina, porém com uma carteira de clientes de alto-nível, que se espalha por todo o território Nacional.
      </p>
      
      {/* <ul>
        <li>Atuamos no gerenciamento total da obra, da fundação ao acabamento, do levantamento planialtimétrico e concepção do projeto, até a entrega.</li>
        <li>Aliada com inovação, responsabilidade, tecnologia, qualidade, agilidade e eficiência gerencial.</li>
        <li>Para isso, contamos com profissionais experientes e qualificados, atuantes em diferentes áreas da construção civil, capazes de atender de forma ágil as necessidades de cada cliente.</li>
        <li>Para maximizar o grau de satisfação dos clientes e colaboradores, a GM Engenharia Dimensional entende que é fundamental assegurar as condições de trabalho propícias à boa execução dos seus serviços, e à satisfação de todos que com ela interagem, bem como desempenhar sua função social de preservação do meio ambiente.</li>
        <li>Nossa empresa possui escritório no Recife e Petrolina, porém com uma carteira de clientes de alto-nível, que se espalha por todo o território do nacional.</li>
      </ul> */}
      
    </AboutUsContainer>
  )
}

export default AboutUs;
