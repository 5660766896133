import React, { useEffect, useState } from "react";
import { CarouselContainer, Slide } from "./carouselStyles";
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import { ButtonPrimary } from "../../styles/buttons";
import { getBanners } from "../../providers/mainProvider";

const slides = [
  (
    <div className="slide" id="img1">
      <h2>Quer uma consultoria topográfica gratuita?</h2>
      <p>Entre em contato com a nossa equipe agora mesmo!</p>
    </div>
  ),
  (
    <div className="slide" id="img2">
      <h2>Topografia dimensional</h2>
      <p>A GM Engenharia Dimensional é uma empresa que atua no segmento da Agrimensura, e tem como especialidade a Topografia de Precisão.</p>
    </div>
  )
]

const Carousel = props => {

  const [banners, setBanners] = useState([]);

  // console.log(banners);


  useEffect(() => {
    const getData = async () => {
      const result = await getBanners();

      setBanners(result);
    }
    getData();
  }, [])

  return (
    <CarouselContainer>
      <AwesomeSlider
        organicArrows={true}
        bullets={true}
      >
        {banners.map(banner => (
          <div className="slide" key={`banner-${banner.id}`}>
            <Slide photo={banner.image}>
              <h2>{banner.name}</h2>
            </Slide>
          </div>
        ))}
      </AwesomeSlider>
      <div className="overlay-banner">
        <div className="overlay-cta">
          <a href="#contato">
            <ButtonPrimary>Faça um orçamento</ButtonPrimary>
          </a>
          <a href="https://api.whatsapp.com/send?phone=5587988358743" target="_blank" title="Fale com um consultor">
            <ButtonPrimary className="wpp-cta">Fale com um consultor</ButtonPrimary>
          </a>
        </div>
      </div>
    </CarouselContainer>
    
  )
}

export default Carousel;
