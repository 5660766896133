// const baseUrl = "https://admin.gmdimensional.com.br/api/"
const baseUrl = "http://gmdimensional-api.devaribox.co/api/";

export const getBanners = async () => {
  try {
    const result = await fetch(`${baseUrl}v1/banner/`);

    if (!result.ok) {
      throw { error: true };
    }

    const data = await result.json();
    // console.log(data);
    return data;
  } catch (error) {
    console.log(error);
    return { error: true };
  }
};

export const getCustomers = async () => {
  try {
    const result = await fetch(`${baseUrl}v1/client/`);

    if (!result.ok) {
      throw { error: true };
    }

    const data = await result.json();
    // console.log(data);
    return data;
  } catch (error) {
    console.log(error);
    return { error: true };
  }
};

export const sendContactMessage = async (payload) => {
  try {
    const response = await fetch(`${baseUrl}v1/contact/`, {
      method: "post",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
      }
    });

    if (!response.ok) {
      console.log(response);
      throw { message: "Não foi possível enviar o contato" };
    }
    const result = await response.json();
    console.log(result);

    return { error: false, result };
  } catch (error) {
    console.log(error);

    return { error: true, message: error.message };
  }
};

export const getBlogPosts = async (page) => {
  try {
    const result = await fetch(`${baseUrl}v1/blog-post/?limit=10&offset=${10*(page-1)}&page=${page}`);

    if (!result.ok) {
      throw { error: true };
    }

    const data = await result.json();
    // console.log(data);
    return data;
  } catch (error) {
    console.log(error);
    return { error: true };
  }
};

export const getTestimonials = async () => {
  try {
    const result = await fetch(`${baseUrl}v1/testimonial/`);

    if (!result.ok) {
      throw { error: true };
    }

    const data = await result.json();
    // console.log(data);
    return data;
  } catch (error) {
    console.log(error);
    return { error: true };
  }
};

export const getServices = async () => {
  try {
    const result = await fetch(`${baseUrl}v1/service/`);

    if (!result.ok) {
      throw { error: true };
    }

    const data = await result.json();
    // console.log(data);
    return data;
  } catch (error) {
    console.log(error);
    return { error: true };
  }
};
