import styled from "styled-components";

export const CarouselContainer = styled.section`

  /* padding-top: var(--header-height); */
  position: relative;
  //width: 100vw;
  //height: calc(100vh - var(--header-height));

  .awssld {
    /* --slider-height-percentage: calc(100vh - var(--header-height)); */
    --slider-height-percentage: 100vh;
    --slider-transition-duration: 700ms;
    --organic-arrow-thickness: 2px;
    --organic-arrow-border-radius: 0px;
    --organic-arrow-height: 31px;
    --organic-arrow-color: #ffffff;
    --control-button-width: 5%;
    --control-button-height: 25%;
    --control-button-background: transparent;
    --control-bullet-color: #ffffff;
    --control-bullet-active-color: #cf3438;
    --loader-bar-color: #851515;
    --loader-bar-height: 6px;
  }

  .awssld__wrapper {
    /* max-height: calc(100vh - var(--header-height)); */
    max-height: 100vh;
  }

  /* .awssld__content {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  } */

  .awssld__bullets {
    bottom: 20px;
    z-index: 4;
    button {
      background: var(--color-bg);
    }
    .awssld__bullets--active:hover {
      transform: scale(1.2);
    }
    .awssld__bullets--active {
      transform: scale(1.2);
    }
  }

  .slide {
    color: #ffffff;
    /* padding: 1rem; */
    /* max-width: 800px; */
    height: 100%;
    width: 100%;

    h2 {
      font-size: 2rem;
      font-weight: bold;
      text-align: center;
      margin-bottom: 1rem;
      color: #ffffff;
    }

    p {
      font-weight: 500;
      text-align: center;
    }

    @media (min-width: 900px) {
      h2 {
        font-size: 3rem;
      }
    }
  }

  .overlay-banner {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 3;
    /* background-color: rgba(0,0,0,0.3); */
    display: flex;
    justify-content: center;

    .overlay-cta {
      position: absolute;
      bottom: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      /* background-color: var(--color-primary);
      color: var(--color-bg); */
      & > * {
        margin: 0 1rem;

        & .wpp-cta {
          background-color: var(--wpp-color);
        }
      }
      @media (max-width: 485px) {
        flex-direction: column;
        & > * {
          margin: 0.5rem 0;
          width: 100%;
          & button {
            width: 100%;
          }
        }
      }
    }
  }
  
`

export const Slide = styled.div`
  
  background-image: ${props => `linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), ${`url(${props.photo})`}`};

  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

`
