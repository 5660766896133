import styled from "styled-components";

export const ServicesContainer = styled.section`

  padding: 4rem 2rem;
  background-color: var(--color-secondary);
  color: var(--color-bg);

  h2 {
    font-size: 1.5rem;
    text-transform: uppercase;
    font-weight: 600;
  }

  h2, h3 {
    margin: 0.25rem 0;
  }

  h4 {
    font-size: 1.5rem;
    margin: 1rem 0;
  }

  .carousel-services {
    max-width: 1100px;
    margin: auto;
    .carousel__slider.carousel__slider--horizontal {
      max-width: 1100px;
      margin: auto;
    }
    h4 {
      text-align: center;
    }
    p {
      text-align: center;
    }
    img {
      object-fit: cover;
      width: 100%;
      height: 280px;
    }
  }

  @media (min-width: 340px) {
    .carousel__slider.carousel__slider--horizontal {
      max-height: 1050px;
    }
  }

  @media (min-width: 420px) {
    .carousel__slider.carousel__slider--horizontal {
      max-height: 900px;
    }
  }

  @media (min-width: 525px) {
    .carousel__slider.carousel__slider--horizontal {
      max-height: 760px;
    }
  }

  @media (min-width: 669px) {
    .carousel__slider.carousel__slider--horizontal {
      max-height: 640px;
    }
  }

  @media (min-width: 934px) {
    .carousel__slider.carousel__slider--horizontal {
      max-height: 1060px;
    }
    .slide-service {
      margin: 0 1rem;
    }
  }

  @media (min-width: 1150px) {
    .carousel__slider.carousel__slider--horizontal {
      max-height: 900px;
    }
  }

  /* li {
    margin: 2rem 0;
    font-size: 1rem;
    h4 {
      text-align: center;
    }
    p {
      text-align: center;
    }
    img {
      object-fit: cover;
      width: 100%;
      height: 280px;
    }
  } */


  /* @media (min-width: 669px) {
    ul {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 1.5rem;
      max-width: 1200px;
      margin: auto;
    }
  }

  @media (min-width: 1100px) {
    ul {
      grid-template-columns: 1fr 1fr 1fr;
      gap: 2rem;
    }
  }

  @media (min-width: 1300px) {
    ul {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  } */
  
`