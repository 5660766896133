import styled from "styled-components";

export const ContactContainer = styled.section`

  padding: 2rem 1rem;

  .section-header::after {
    background-color: var(--color-font);
  }

  p {
    text-align: center;
    font-weight: 500;
    margin: 0;
  }

  form {
    padding: 0 1rem;
    max-width: 900px;
    margin-right: auto;
    margin-left: auto;
    .column-block {
      grid-column-end: span 2;
    }
    button {
      width: 100%;
    }
    @media (min-width: 600px) {
      display: grid;
      column-gap: 1rem;
      grid-template-columns: 1fr 1fr;
      padding: 0 2rem;
    }
  }

  
`