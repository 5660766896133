import React from "react";
import { FooterContainer } from "./footerStyles";

import Logo from "../../assets/logo-symbol.png";
import { Button, ImageButton } from "../../styles/buttons";

const Footer = props => {
  return (
    <FooterContainer>

      <section className="logo-container">
        <ImageButton width="162px" height="60px" icon={Logo}>
          Contabilizar
        </ImageButton>
      </section>

      <section className="contact-info">
        <ul>
          <li className="email">contato@gmdimensional.com.br</li>
          <li className="address">Av. Souza Filho, Nº 1089 - CEP 56304-000 - Centro - Petrolina - PE</li>
          <li className="phone">87 98835-8743</li>
          <li className="address">R. Silveira Lôbo, Nº 32 - CEP 52061-030 - Poço da Panela - Recife - PE</li>
          <li className="phone">81 99743-4646</li>
        </ul>
      </section>

      <section>
        <ul>
          <li>
            <a href="/#quem-somos" >
              Quem somos nós
            </a>
          </li>
          <li>
            <a href="/#servicos" >
              Serviços
            </a>
          </li>
          <li>
            <a href="/#contato" >
              Contato
            </a>
          </li>
        </ul>
        
      </section>
      
    </FooterContainer>
  )
}

export default Footer;
