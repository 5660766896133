import React, { useState } from "react";
import { sendContactMessage } from "../../providers/mainProvider";
import { ButtonPrimary } from "../../styles/buttons";
import { Alert, AlertSucess, Form, Input, Label, Textarea } from "../../styles/forms";
import { ContactContainer } from "./contactStyles";

const initialPayload = {
  name: "",
  email: "",
  phone: "",
  subject: "",
  message: "",
}

const Contact = props => {

  const [payload, setPayload] = useState(initialPayload);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const onChangePayload = e => {
    setPayload({...payload, [e.target.name]: e.target.value});
  }

  const isValid = () => {

    if(Object.values(payload)
      .some((value) => value === "")) {
        setError("Preencha todos os campos para enviar sua mensagem");
        return false;
    }

    return true;
  }

  const send = async e => {
    e.preventDefault();
    setError("");
    setSuccess("");

    if(!isValid()) return;

    setLoading(true);

    const result = await sendContactMessage(payload);
    setLoading(false);

    if(result.error) {
      setError("Não foi possível enviar o contato");
      return;
    }
    setPayload(initialPayload);
    setSuccess("Contato enviado com sucesso");
    setTimeout(() => {
      setSuccess("");
    }, 7000);
  } 

  return (
    <ContactContainer id="contato">
      <header className="section-header">
        <h2>Entre em contato</h2>
        <p>Será um prazer falar com você</p>
      </header>


      <Form>
        <Label>
          Nome
          <Input name="name" value={payload.name} onChange={onChangePayload} />
        </Label>
        <Label>
          E-mail
          <Input name="email" value={payload.email} onChange={onChangePayload} />
        </Label>
        <Label>
          Telefone
          <Input name="phone" value={payload.phone} onChange={onChangePayload} />
        </Label>
        <Label>
          Assunto
          <Input name="subject" value={payload.subject} onChange={onChangePayload} />
        </Label>
        <Label className="column-block">
          Mensagem
          <Textarea rows="7" name="message" value={payload.message} onChange={onChangePayload} />
        </Label>

        <Alert className="column-block" show={error}>{error}</Alert>
        <AlertSucess className="column-block" show={success}>{success}</AlertSucess>

        <ButtonPrimary onClick={send} className="column-block">
          {loading ? 'Aguarde...' : 'Enviar'}
        </ButtonPrimary>
      </Form>
      
      
    </ContactContainer>
  )
}

export default Contact;
