import styled from "styled-components";


export const LandingHeader = styled.header`
  /* --header-height: 100px; */
  z-index: 99;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  height: ${({shrunk=false}) => shrunk ? `calc(0.7 * var(--header-height))` : `var(--header-height)`};
  box-shadow: ${({shrunk=false}) => shrunk ? `0px 0px 3px 0px rgba(46,65,127,0.2)` : `none`};
  background-color: ${({shrunk=false}) => shrunk ? `var(--color-bg)` : `var(--header-top-color)`};
  #menu-btn {
    width: 110px;
    font-weight: 600;
    color: ${({shrunk=false}) => shrunk ? `var(--color-font)` : `var(--header-content-color)`};
  }
  @media (min-width: 580px) {
    grid-template-columns: 100px auto;
    #menu-btn {
      display: none;
    }
  }
`

export const NavMenu = styled.nav`
  display: inline-block;
  & > a button {
    color: ${({shrunk=false}) => shrunk ? `var(--color-font)` : `var(--header-content-color)`};
  }
  
  @media (max-width: 579px) {
    display: ${({ show = false }) => show ? "block" : "none"};
    background-color: var(--color-secondary);
    position: fixed;
    width: 100vw;
    top: ${({shrunk=false}) => shrunk ? `calc(0.7 * var(--header-height))` : `var(--header-height)`};
    height: ${({shrunk=false}) => shrunk ? `calc(100vh - var(--header-height) * 0.7)` : `calc(100vh - var(--header-height))`};
    left: 0;
    flex-direction: column;
    padding: 1rem;
    #login-btn {
      border: none;
      margin: 0;
    }
    & > * {
      display: block;
    }
    & > *, & > a button {
      color: var(--color-bg);
    }
  }
`;
