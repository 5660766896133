import React, { useEffect, useState } from "react";
import { getCustomers } from "../../providers/mainProvider";
import { CustomersContainer } from "./customersStyles";
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

const Customers = props => {

  const [customers, setCustomers] = useState([]);
  const [visibleSlides, setVisibleSlides] = useState(1);

  useEffect(() => {
    const width = window.screen.width;
    if(width >= 890) {
      setVisibleSlides(4);
      return;
    }
    // if(width > 590) {
    //   setVisibleSlides(2);
    //   return;
    // }
  }, []);

  useEffect(() => {
    const getData = async () => {
      let result = await getCustomers();
      //result.push(result[0]); //to test odd/even cases
      const separator = result.length % 2 === 0 ? result.length/2 : result.length/2 + 1;
      // console.log(result);
      const result1 = result.slice(0, separator);
      const result2 = result.slice(separator);
      // console.log("result1",result1);
      // console.log("result2",result2);
      const resultFinal = result1.map((item, index) => ({
        item1: item,
        item2: result2[index] ? result2[index] : {}
      }));
      // console.log("resultFinal",resultFinal);
      setCustomers(resultFinal);
    }
    getData();
  }, []);

  return (
    <CustomersContainer id="clientes">
      <header className="section-header">
        <h2>Nossos clientes</h2>
      </header>

      <CarouselProvider
        naturalSlideWidth={120}
        naturalSlideHeight={200}
        totalSlides={customers.length}
        isPlaying={true}
        interval={6000}
        infinite={true}
        visibleSlides={visibleSlides}
        className="pure-carousel carousel-customers"
      >
        <Slider>
            
          {customers.map((customer,index) => (
            <Slide index={index} key={`customer-${customer.item1.id}`}>
              <div className="slide-customers">
                <img
                  src={customer.item1.image}
                  alt={customer.item1.name}
                />
              </div>
              <div className="slide-customers">
                <img
                  src={customer.item2.image}
                  alt={customer.item2.name}
                />
              </div>
            </Slide>
          ))}
              
        </Slider>
        <ButtonBack className="alt-color"></ButtonBack>
        <ButtonNext className="alt-color"></ButtonNext>
        {/* <DotGroup /> */}
      </CarouselProvider>

      {/* <div className="image-grid">

        {customers.map(customer => (
          <img
            key={`customer-${customer.id}`}
            src={customer.image}
            alt={customer.name}
          />
        ))}

      </div> */}
      
      
    </CustomersContainer>
  )
}

export default Customers;
