import styled from "styled-components";

export const TestimonialsContainer = styled.section`

  padding: 2rem 2rem;
  background-color: var(--color-secondary);
  color: var(--color-bg);

  .awssld {
    --content-background-color: none;
    --slider-height-percentage: 50vh;
  }
  .awssld__timer {
    display: none;
  }

  .carousel-testimonials {
    max-width: 1100px;
    margin: auto;

    .carousel__slider.carousel__slider--horizontal {
      max-width: 1100px;
      margin: auto;
    }

    .slide-testimony {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: start;
      height: 100%;
      max-width: 900px;
      margin:auto;
    }

    img {
      height: 100px;
      width: 100px;
      border-radius: 50%;
      object-fit: cover;
    }

    p {
      margin: 1.5rem 0;
    }

    h3 {
      font-size: 1.175rem;
      margin: 0;
      margin-top: 2rem;
      font-weight: 900;
    }

    h4 {
      font-size: 1.5rem;
      font-weight: 300;
      margin: 1rem 0;
    }

    @media (min-width: 340px) {
    .carousel__slider.carousel__slider--horizontal {
      max-height: 560px;
      }
    }

    @media (min-width: 525px) {
      .carousel__slider.carousel__slider--horizontal {
        max-height: 420px;
      }
    }

    @media (min-width: 669px) {
      .carousel__slider.carousel__slider--horizontal {
        max-height: 440px;
      }
    }

  }
    

    @media (min-width: 580px) {
      /* padding: 2rem 8rem; */
    }
  
  
`