import styled from "styled-components";
import addressIcon from "../../assets/map-marker-alt-solid.svg";
import phoneIcon from "../../assets/phone-volume-solid.svg";
import mailIcon from "../../assets/envelope-solid.svg";


export const FooterContainer = styled.footer`
  
  background-color: var(--color-bg);
  padding: 1rem 2rem;
  font-weight: 500;

  & > section {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0 0.5rem;

    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }

    &.logo-container {
      align-items: center;
      button {
        cursor: default;
      }
    }

    &.contact-info {
      padding-left: 1rem;
    }

    ul > li {
      margin: 1rem 0;

      &.address {
        list-style-image: ${`url(${addressIcon})`};
      }
      &.phone {
        list-style-image: ${`url(${phoneIcon})`};
      }
      &.email {
        list-style-image: ${`url(${mailIcon})`};
      }
    }

  }

  @media (min-width: 769px) {
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    & > section {
      flex: 1;
      &:not(:last-child) {
        margin-bottom: 0;
      }
    }
  }
`
