import React, { useEffect, useState } from "react";
import AwesomeSlider from "react-awesome-slider";
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import { getTestimonials } from "../../providers/mainProvider";
import { CarouselProvider, Slider, Slide, DotGroup, ButtonBack, ButtonNext } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

import { TestimonialsContainer } from "./testimonialsStyles";

const AutoplaySlider = withAutoplay(AwesomeSlider);

const Testimonials = props => {

  const [customers, setCustomers] = useState([])

  useEffect(() => {
    const getData = async () => {
      const result = await getTestimonials();
      setCustomers(result);
    }
    getData();
  }, []);

  return (
    <TestimonialsContainer id="testemunhos">
      <header className="section-header">
        <h2>Satisfação de nossos clientes</h2>
      </header>

      <CarouselProvider
        naturalSlideWidth={4}
        naturalSlideHeight={12}
        totalSlides={customers.length}
        isPlaying={true}
        infinite={true}
        interval={8000}
        className="pure-carousel carousel-testimonials"
      >
        <Slider>
            
          {customers.map((item,index) => (
            <Slide index={index} key={`testimony-${item.id}`}>
              <div className="slide-testimony">
                <img src={item.client_image} />
                <p>{item.testimonial_text}</p>
                <h3>{item.client_name}</h3>
                <h4>{item.client_position}</h4>
              </div>
            </Slide>
          ))}
              
        </Slider>
        <ButtonBack></ButtonBack>
        <ButtonNext></ButtonNext>
        <DotGroup />
      </CarouselProvider>


      {/* <AutoplaySlider
        bullets={false}
        play={true}
        interval={5000}
        cancelOnInteraction={true}
      >
        {customers.map(testimonial => (
          <div key={`testimonial-${testimonial.id}`} className="slide-testimonials">
            <img src={testimonial.client_image} />
            <p>{testimonial.testimonial_text}</p>
            <h3>{testimonial.client_name}</h3>
            <h4>{testimonial.client_position}</h4>
          </div>
        ))}
      </AutoplaySlider> */}
      
      
    </TestimonialsContainer>
  )
}

export default Testimonials;
