import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import HomeScreen from './screens/HomeScreen';
import './styles/app.css';

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import BlogScreen from './screens/BlogScreen';

function App() {
  return (
    <div className="App">
      <Router>

        <Switch>
          <Route path="/noticias">
            <BlogScreen />
          </Route>
          <Route path="/">
            <HomeScreen />
          </Route>
        </Switch>

        <a className="wpp-button" href="https://api.whatsapp.com/send?phone=5587988358743" target="_blank" title="Fale com um consultor">
          <i className="wpp-icon"></i>
        </a>
      <Footer />

      </Router>

    </div>
  );
}

export default App;
